import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment-timezone";
import Markdown from "../../components/utility/markdown";

import Seo from "../../components/seo"
import Prose from "../../components/utility/prose"

const NotFoundPage = ({data}) => {

    function slugify(text) {
        const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
        const to = "aaaaaeeeeeiiiiooooouuuunc------"

        const newText = text.split('').map(
            (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

        return newText
            .toString()                     // Cast to string
            .toLowerCase()                  // Convert the string to lowercase letters
            .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
            .trim()                         // Remove whitespace from both sides of a string
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/&/g, '-y-')           // Replace & with 'and'
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }

        const nonConstructionPosts = data.allStrapiBlogPost.nodes.filter((value, index, array) => {
        let construction = false
        value.tags.forEach((tag) => {
            // console.log(tag)
            if (tag.title === "Construction Update") {
                construction = true
            }
        })

        return !construction
    })

    const sortedPosts = nonConstructionPosts.sort((a,b) => {
            const aPublishedAt = a.publish_at !== null ? a.publish_at : a.publishedAt
            const bPublishedAt = b.publish_at !== null ? b.publish_at : b.publishedAt
        return moment(bPublishedAt) - moment(aPublishedAt)
    })
  return (
  <>
    <Prose>
    <Seo title="News Archive" />
    <h1 className="mb-12">News Archive</h1>
    </Prose>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
    {sortedPosts.map(node => {
        const publishedAt = node.publish_at !== null ? node.publish_at : node.publishedAt
        const slug = slugify(node.title)
        let date = moment.utc(publishedAt).tz("America/Chicago").format("YYYY-MM-DD")

        return (
        <div>
        <Link to={`/posts/${date}/${slug}`} className="h-full transition group flex flex-col justify-start bg-gray-100 dark:bg-gray-800 rounded-3xl shadow-xl hover:shadow-2xl hover:-translate-y-3">
            <GatsbyImage className="rounded-t-3xl" imgClassName="!transition-all group-hover:brightness-110" image={node.thumbnail.localFile.childImageSharp.gatsbyImageData} alt={node.thumbnail.alternativeText} />
            <div className="mt-4 px-4 flex-grow">
            <Prose>
            <h3 className="mb-0">{node.title}</h3>
            <small className="inline-block mb-4">{moment(publishedAt).format("ll")}</small>
            </Prose>
            <Markdown body={node.description} />
            </div>
            <span>
            <span className="inline-block bg-emerald-300 dark:bg-emerald-700 px-4 py-2 mx-4 my-4 rounded-full">READ MORE</span>
            </span>
        </Link>
        </div>
    )})}
    </div>
  </>
)}

export default NotFoundPage

export const archivePageQuery = graphql`
  query archivePage {
    allStrapiBlogPost(
        sort: {fields: publishedAt, order: DESC}
        filter: {locale: {eq: "en"}}
    ) {
        nodes {
            id
            title
            tags {
                title
            }
            description
            publish_at
            publishedAt
            externalLink
            thumbnail {
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 600
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
    }
  }
`
